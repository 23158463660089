import * as React from 'react';
import { Link, graphql } from 'gatsby';

// import Bio from "../components/bio"
// import Layout from "../components/layout"
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storyblokEditable, useStoryblokState } from 'gatsby-source-storyblok';
import { render, NODE_HEADING, MARK_LINK } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import Seo from '../components/seo';
import LayoutConsumer from '../components/layout-context';
import {
  BlogPostHighlightBox,
  BlogPostTableOfContents,
  BlogPostTable,
  BlogPostMediaImage,
  BlogPostMediaVideo,
  StoryblokImage,
  BlogPostFAQ,
  BlogPostBigNumbers,
  BlogPostFormula,
  QuoteWithImage,
  CtaButton,
} from '../components/storyblok';
import * as icons from '../icons/icon-pack';
import { slugify } from '../lib/utils';
import LinkResolver from '../helpers/link-resolver';

function NodeHeading(children, props) {
  const { level } = props;
  const Heading = `h${level}`;
  const hStyles = {
    3: '',
    4: '',
  };
  const styles = clsx(
    'scroll-mt-24 hyphens-auto', // see https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far/56391657#answer-67923821
    hStyles[level],
  );
  let id = null;
  if (children) {
    const [firstChild] = children;
    if (typeof firstChild === 'string') {
      id = slugify(firstChild);
    } else if (firstChild.props) {
      id = slugify(firstChild.props.children);
    }
  }
  return (
    <Heading id={id} className={styles}>{children}</Heading>
  );
}

function HighlightBox(props) {
  return <BlogPostHighlightBox {...props} />;
}

function Table(props) {
  return <BlogPostTable {...props} />;
}

function MediaVideo(props) {
  return <BlogPostMediaVideo {...props} />;
}
function FAQ(props) {
  return <BlogPostFAQ {...props} />;
}
function BigNumbers(props) {
  return <BlogPostBigNumbers {...props} />;
}
function Formula(props) {
  return <BlogPostFormula {...props} />;
}

function BlogPostTemplate({ data, location }) {
  library.add(icons);

  const post = useStoryblokState(data.storyblokEntry);
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const { previous, next } = data;

  const PublishedDate = ({ firstPublishedAt }) => {
    const _firstPublishedAt = new Date(firstPublishedAt);
    const langs = {
      de: 'de-DE',
      en: 'en-GB',
      default: 'de-DE',
    };
    const lang = langs[post.lang] || langs.default;

    return _firstPublishedAt.toLocaleDateString(lang, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const image = post?.content?.hero;
  const storyName = post
    .translated_slugs.filter((slug) => slug.lang === post.lang)[0]?.name || post.name;

  const MediaImage = React
    .useCallback(
      (props) => <BlogPostMediaImage {...props} articleTitle={post.name} />,
      [post.name],
    );

  const TableOfContents = React.useCallback((props) => {
    const listOfHeading = post?.content?.content?.content
      ?.filter((k) => k.type === 'heading')
      .filter((l) => l.attrs.level === 2)
      .map((m) => m.content
        ?.filter((n) => n.type === 'text'))
      ?.map((o) => o?.length > 0 && o[0]);
    return (
      <BlogPostTableOfContents
        {...props}
        headings={listOfHeading || []}
        location={location}
      />
    );
  }, [location, post?.content?.content?.content]);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: siteTitle, fullWidth: true });
        return (
          <>
            <Seo
              title={post.content.seo_title}
              description={post.content.seo_description || ''}
            />
            <article
              itemScope
              itemType="http://schema.org/Article"
              className="blog-post w-full"
            >
              {image && (
                <div className="pb-8">
                  <StoryblokImage
                    alt={post.name}
                    src={image}
                    ratios={{ ratio: '4:3', ratioSm: '16:9' }}
                  />
                </div>
              )}
              <div className="max-w-full md:max-w-[80%] px-4 lg:px-16 mx-auto prose">
                <div className="flex md:block flex-col mb-[100px]">
                  <div className="inline-flex justify-start items-center gap-2">
                    <FontAwesomeIcon icon={icon(icons.faClock)} className="text-latori-green" />
                    <span>
                      <PublishedDate firstPublishedAt={post?.first_published_at} />
                    </span>
                  </div>
                  {Array.isArray(post.tag_list) && post.tag_list.length > 0 && (
                    <div className="md:pl-4 inline-flex justify-start items-center gap-2">
                      <FontAwesomeIcon icon={icon(icons.faFolderClosed)} className="text-latori-green" />
                      {post.tag_list.map((tag, index) => (
                        <span key={tag}>
                          <Link to={`/blog/${slugify(tag)}`}>{tag}</Link>
                          {index < post.tag_list.length - 1 ? ',' : ''}
                        </span>
                      ))}
                    </div>
                  )}
                  {post?.content?.author?.name && (
                    <div className="md:pl-4 inline-flex justify-start items-center gap-2">
                      <FontAwesomeIcon icon={icon(icons.faUser)} className="text-latori-green" />
                      <span>{post?.content?.author?.name || ''}</span>
                    </div>
                  )}
                </div>
                <header>
                  <h1 itemProp="headline">{storyName}</h1>
                </header>
                <section
                  itemProp="articleBody"
                  {...storyblokEditable(post.content)}
                  className="blog-post-content"
                >
                  {render(post.content.content, {
                    markResolvers: {
                      [MARK_LINK]: LinkResolver,
                    },
                    nodeResolvers: {
                      [NODE_HEADING]: NodeHeading,
                    },
                    blokResolvers: {
                      /* eslint-disable no-useless-computed-key */
                      ['blogpost-highlight-box']: HighlightBox,
                      ['blogpost-table-of-contents']: TableOfContents,
                      ['blogpost-table']: Table,
                      ['blogpost-media-image']: MediaImage,
                      ['blogpost-media-video']: MediaVideo,
                      ['FAQ Collapsible']: FAQ,
                      ['Big Numbers']: BigNumbers,
                      ['blogpost-formula']: Formula,
                      ['quote-with-image']: QuoteWithImage,
                      ['cta-button']: CtaButton,
                    },
                  })}
                </section>
                <div className="pb-24 pt-12">
                  <nav className="blog-post-nav">
                    <ul
                      className="flex flex-wrap justify-between items-center p-0 list-none"
                    >
                      <li className="md:pr-2 md:max-w-[50%] md:block">
                        {previous && (
                          <Link
                            className="no-underline flex justify-center items-center gap-2 font-monospace text-latori-green"
                            to={`/${previous.full_slug}`}
                            rel="prev"
                          >
                            <span>←</span>
                            <span>
                              {previous
                                .translated_slugs
                                .filter(
                                  (slug) => slug.lang === post.lang,
                                )[0]?.name || previous.name}
                            </span>
                          </Link>
                        )}
                      </li>
                      <li className="md:pl-2 md:max-w-[50%] md:block mt-4 sm:mt-0">
                        {next && (
                          <Link
                            className="no-underline flex justify-center items-center gap-2 font-monospace text-latori-green text-right"
                            to={`/${next.full_slug}`}
                            rel="next"
                          >
                            <span>
                              {next
                                .translated_slugs
                                .filter((slug) => slug.lang === post.lang)[0]?.name || next.name}
                            </span>
                            <span>→</span>
                          </Link>
                        )}
                      </li>
                    </ul>
                  </nav>
                  <div className="pt-12">
                    <p>Teilen:</p>
                    <div className="flex gap-4 flex-cols pt-2">
                      <Link
                        to={`https://facebook.com/sharer.php?u=${typeof window !== 'undefined' && window.location.href}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-facebook" size="lg" />
                      </Link>
                      <Link
                        to={`https://twitter.com/intent/tweet?text=${typeof document !== 'undefined' && escape(document.title)}&url=${typeof window !== 'undefined' && window.location.href}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-twitter" size="lg" />
                      </Link>
                      <Link
                        to={`https://www.linkedin.com/shareArticle?mini=true&url=${typeof window !== 'undefined' && window.location.href}&title=${typeof document !== 'undefined' && escape(document.title)}&source=Latori`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-linkedin" size="lg" />
                      </Link>
                    </div>
                  </div>
                  {/* <footer>
                    <Bio />
                  </footer> */}
                </div>
              </div>
            </article>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query StoryblokBlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $lang: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    storyblokEntry(id: {eq: $id}) {
      id
      internalId
      uuid
      name
      content
      full_slug
      lang
      first_published_at
      slug
      tag_list
      translated_slugs {
        lang
        name
      }
    }
    previous: storyblokEntry(uuid: {eq: $previousPostId}, lang: {eq: $lang}) {
      uuid
      name
      full_slug
      slug
      lang
      translated_slugs {
        lang
        name
      }
    }
    next: storyblokEntry(uuid: {eq: $nextPostId}, lang: {eq: $lang}) {
      uuid
      name
      full_slug
      slug
      translated_slugs {
        lang
        name
      }
    }
  }
`;
